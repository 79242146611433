import React from "react";
import styles from "./Information.module.css";
import CallMadeIcon from '@material-ui/icons/CallMade';
import checkMark from "../../Utils/Images/checkmark.png";

export function SoldItemName() {
  const queryString = require('query-string');
  const parsed = queryString.parse(window.location.search);

  return (
    <>
      {parsed.adTitle &&
        <div className={styles.soldItemName}>
          <div className={styles.soldItemNamePretext}>
            Försäkra
        </div>
          {parsed.adTitle}{parsed.salePrice && `, ${parsed.salePrice} kr`}
        </div>
      }
    </>
  )
}

export function InsuranceText() {
  return (
    <div className={styles.insuranceText}>
      <div className={styles.large}>
        I samarbete med Blocket har vi på Omocom skapat en försäkring
        som skyddar ditt fynd mot dolda fel i upp till 14 dagar - utan självrisk!
      </div>
      <div className={`${styles.insuranceSubheader} ${styles.medium}`}>
        Vi vill att det ska kännas tryggt att handla begagnat.
        Därför skapade vi Dolda fel-försäkringen som ger upp till 10.000 kr i ersättning för
        reparation och reservdelar ifall din produkt inte fungerar som förväntat. Nedan kan du läsa mer
        om vad som ingår och tveka inte på att kontakta oss på Omocom om du har några funderingar.
      </div>
    </div>
  )
}

export function CompensationList() {
  return (
    <ul className={styles.compensationWrapper}>
      <li className={styles.compensationItem}>
        <span className={styles.compensationIcon}><img src={checkMark} alt={''} /></span>
        <div className={`${styles.compensationText} ${styles.medium}`}>Om funktioner såsom mikrofon, kamera, display eller högtalare slutar fungera</div>
      </li>
      <li className={styles.compensationItem}>
        <span className={styles.compensationIcon}><img src={checkMark} alt={''} /></span>
        <div className={`${styles.compensationText} ${styles.medium}`}>Om prylen har ett trasigt batteri eller om den till exempel glappar vid laddning</div>
      </li>
      <li className={styles.compensationItem}>
        <span className={styles.compensationIcon}><img src={checkMark} alt={''} /></span>
        <div className={`${styles.compensationText} ${styles.medium}`}>Försäkringen är giltig i 14 dagar och gäller för reservdelar eller lagning upp till maximalt 10 000 kr</div>
      </li>
      <li className={styles.compensationItem}>
        <span className={styles.compensationIcon}><img src={checkMark} alt={''} /></span>
        <div className={`${styles.compensationText} ${styles.medium}`}>Ingen självrisk</div>
      </li>
    </ul>
  )
}

export function Disclaimer() {
  return (
    <div className={`${styles.compensationText} ${styles.medium}`}>Vid konstaterat dolt fel lämnar försäkringen som mest ersättning upp till maxnivån i ditt försäkringspaket. Ersättningen uppgår dock aldrig till mer än det pris som du betalade för varan.</div>
  )
}

export function ExternalLinks() {
  return (
    <div className={styles.linksWrapper}>
      <div className={styles.link}>
        <a target="_blank" rel="noreferrer" href="https://www.omocom.insurance/Blocket/Terms">Fullständiga villkor</a>
        <span>
          <CallMadeIcon style={{ 'height': '10px', 'width': '10px', 'paddingLeft': '5px' }} />
        </span>
      </div>
	  <div className={styles.link}>
        <a target="_blank" rel="noreferrer" href="https://www.omocom.insurance/Blocket/IPID">Förköpsinformation</a>
        <span>
          <CallMadeIcon style={{ 'height': '10px', 'width': '10px', 'paddingLeft': '5px' }} />
        </span>
      </div>
    </div>
  )
}

const Information = { SoldItemName, InsuranceText, CompensationList, Disclaimer, ExternalLinks }

export default Information;
